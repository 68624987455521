// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-direct-waitlist-js": () => import("./../../../src/pages/direct-waitlist.js" /* webpackChunkName: "component---src-pages-direct-waitlist-js" */),
  "component---src-pages-email-subscription-confirmation-js": () => import("./../../../src/pages/email-subscription-confirmation.js" /* webpackChunkName: "component---src-pages-email-subscription-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-landing-page-js": () => import("./../../../src/pages/index-old-landing-page.js" /* webpackChunkName: "component---src-pages-index-old-landing-page-js" */),
  "component---src-pages-observer-js": () => import("./../../../src/pages/observer.js" /* webpackChunkName: "component---src-pages-observer-js" */),
  "component---src-pages-pin-deck-tsx": () => import("./../../../src/pages/pin/deck.tsx" /* webpackChunkName: "component---src-pages-pin-deck-tsx" */),
  "component---src-pages-pin-how-and-why-blahnope-tsx": () => import("./../../../src/pages/pin/how-and-why-blahnope.tsx" /* webpackChunkName: "component---src-pages-pin-how-and-why-blahnope-tsx" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */)
}

